$(function () {
    setTimeout(function () {
        $("#hiveRow").show();
        $("#desktopHive").slideDown();
    }, 2000);

    $('#newDatasheetsContainer').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true
                }
            }
        ]
    });

    $("#gridTooltip").qtip({
        content: {
            text: "Grids are dynamic, online, customizable spreadsheets that you can easily save and share"
        },
        show: {
            event: 'mouseenter'
        },
        style: {
            tip: true,
            classes: 'qtip-bootstrap'
        },
        position: {
            my: 'top left',
            at: 'bottom center'
        }
    });

    $(".new-datasheet-name").qtip({
        content: {
            text: true
        },
        show: {
            event: 'mouseenter'
        },
        style: {
            tip: true,
            classes: 'qtip-bootstrap'
        },
        position: {
            my: 'top left',
            at: 'bottom left',
            adjust: {
                x: 30
            }
        }
    });

    $(".startSearchButton").on("click", function () {
        window.scrollTo(0, 0);
        openPanel("#mm-1");
    });
});